  .custom-report-th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: #0f71fd ;
  color: white;
  padding: 8px;
  margin-top: -20px;

  }

  .custom-report-td {
  padding-top: 5px;
  padding-bottom: 3px;
  text-align: left;
  padding: 5px;
  }

  .tr-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0 0 6px rgba(0,0,0,0.25);
  }

  .th-sticky,
.td-sticky {
  position: sticky;
  left: 0;
  background-color: white;
}

.td-sticky::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-left: 1px solid var(--borderColor);
  background: linear-gradient(90deg, rgba(0,0,0,0.08) 0%, rgba(0,0,0,0) 100%);
}
  
