@media (min-width: 375px) {
    .team-table{
       max-width: 250px;
}
}
@media (min-width: 400px) {
    .team-table{
       max-width: 280px;
}
}

@media (min-width: 600px) {
    .team-table{
       max-width: 580px;
}
}
@media (min-width: 900px) {
    .team-table{
       max-width: inherit;
}
}