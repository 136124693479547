.container {
  border-radius: 10px;
  padding: 10px;
  /* box-shadow: inset;
  -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4); */
}

.radial-chart {
  width: 100%;
  height: 50%;
}

.dashboard-h1 {
  font-weight: bold;
  font-size: 28px;
}

.dashboard-h2 {
  font-weight: bold;
  font-size: 18px;
}

.dashboard-h3 {
  font-weight: bold;
  font-size: 16px;
}

.dashboard-h3-normal {
  font-size: 16px;
}
.dashboard-color-block {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-flex;
}
