.login-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}
@media (min-width: 200px) {
    .form-wrapper-login{
        margin-top: 30%;
        background-color: white;
        border-radius: 15px;
        width: 100%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        padding-bottom: 2%;
}
}
@media (min-width: 320px) {
    .form-wrapper-login{
        margin-top: 30%;
        background-color: white;
        border-radius: 15px;
        width: 85%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        padding-bottom: 2%;
}
}
@media (min-width: 500px) {
    .form-wrapper-login{
        margin-top: 12%;
        background-color: white;
        border-radius: 15px;
        width: 55%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        padding-bottom: 2%;
}}
@media (min-width: 800px) {
    .form-wrapper-login{
        margin-top: 12%;
        background-color: white;
        border-radius: 15px;
        width: 40%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        padding-bottom: 2%;
}
}
@media (min-width: 1200px) {
    .form-wrapper-login{
        margin-top: 12%;
        background-color: white;
        border-radius: 15px;
        width: 30%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        padding-bottom: 2%;
}
}


.register-link:hover{
    text-decoration: underline;
    cursor: pointer;

}

.forgot-password{
    flex-direction: column;
    margin-top: -15px;
    margin-bottom: 0;
    align-items: center;
}
.forgot-password:hover{
    text-decoration: underline;
    cursor: pointer;

}
.custom {
    display: flex !important;
}