
.container {
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    /* box-shadow: 0px 0px 5px 1px grey; */
    border: 1px solid #E0E0E0
}

.radial-chart{
    width: 100%;
    height: 50%;
}

.dashboard-h1{
    font-weight: bold; font-size: 28px;
}

.dashboard-h2{
    font-weight: bold; font-size: 18px;
}

.dashboard-h3{
    font-weight: bold; font-size: 16px;
}
.dashboard-h4{
    font-weight: bold; font-size: 13px;
}

.dashboard-h3-normal{
    font-size: 16px;
}
.dashboard-color-block {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    display: inline-flex;
}