.container {
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0px 0px 5px 1px grey; */
}

.radial-chart {
  width: 100%;
  height: 50%;
}

.dashboard-h1 {
  font-weight: bold;
  font-size: 28px;
}

.dashboard-h2 {
  font-weight: bold;
  font-size: 18px;
}

.dashboard-h3 {
  font-weight: bold;
  font-size: 16px;
}

.dashboard-h3-normal {
  font-size: 16px;
}
.dashboard-color-block {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-flex;
}
.table {
  min-width: 650;
}
.sticky {
  position: sticky !important;
  background-color: white !important;
  left: 0;
  right: 0;
}
.stickyWB {
  position: sticky !important;
  background-color: white !important;
  left: 0;
  box-shadow: 5px 2px 5px white !important;
  border-right: 2px solid black !important;
}

@media (min-width: 300px) {
  .budget-table {
    max-width: 250px;
  }
}
@media (min-width: 350px) {
  .budget-table {
    max-width: 320px;
  }
  .table-width{
    min-width: 300px
  }
}
@media (min-width: 400px) {
  .budget-table {
    max-width: 350px;
  }
  .table-width{
    min-width: 350px
  }
}
@media (min-width: 450px) {
  .budget-table {
    max-width: 400px;
  }
}
@media (min-width: 500px) {
  .budget-table {
    max-width: 470px;
  }
}

@media (min-width: 600px) {
  .budget-table {
    max-width: 550px;
  }
}
@media (min-width: 700px) {
  .budget-table {
    max-width: 600px;
  }
  .table-width{
    overflow-x: "auto";
    min-width: 600px
  }
}
@media (min-width: 800px) {
  .budget-table {
    max-width: 650px;
  }
  .table-width{
    overflow-x: "auto";
    min-width: 650px
  }
 
}
@media (min-width: 1000px) {
  .budget-table {
    max-width: none;
    width: inherit;
  }
  
 
}

