.spreadsheet-container-report{
    padding-left: 0px
}

@media (min-width: 600px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 82vw;
      overflow: auto;
    }
  }
  @media (min-width: 800px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 84vw;
      overflow: auto;
    }
  }
  @media (min-width: 900px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 84.75vw;
      overflow: auto;
    }
  }
  @media (min-width: 1000px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 85.5vw;
      overflow: auto;
    }
  }
  @media (min-width: 1100px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 87vw;
      overflow: auto;
    }
  }
  @media (min-width: 1200px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 87.5vw;
      overflow: auto;
    }
  }
  @media (min-width: 1300px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 88.5vw;
      overflow: auto;
    }
  }
  @media (min-width: 1400px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 89vw;
      overflow: auto;
    }
  }
  @media (min-width: 1500px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 89.5vw;
      overflow: auto;
    }
  }
  @media (min-width: 1600px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 90vw;
      overflow: auto;
    }
  }
  @media (min-width: 1700px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 90.25vw;
      overflow: auto;
    }
  }
  @media (min-width: 1800px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 90.5vw;
      overflow: auto;
    }
  }
  @media (min-width: 1900px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 90.75vw;
      overflow: auto;
    }
  }
  @media (min-width: 2000px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 91vw;
      overflow: auto;
    }
  }
  @media (min-width: 2100px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 91.25vw;
      overflow: auto;
    }
  }
  
  @media (min-width: 2200px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 91.5vw;
      overflow: auto;
    }
  }
  @media (min-width: 2300px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 92vw;
      overflow: auto;
    }
  }
  @media (min-width: 2400px) {
    .spreadsheet-container-report {
      max-height: 70vh;
      max-width: 92.5vw;
      min-width: 600px;
      overflow: auto;
    }
  }