.forgot-password-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

@media (min-width: 200px) {
    .form-wrapper-forgot-password{
        margin-top: 30%;
        background-color: white;
        border-radius: 15px;
        width: 100%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        padding-bottom: 2%;
}
}
@media (min-width: 320px) {
    .form-wrapper-forgot-password{
        margin-top: 25%;
        background-color: white;
        border-radius: 15px;
        width: 90%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        padding-bottom: 2%;
}
}
@media (min-width: 500px) {
    .form-wrapper-forgot-password{
        margin-top: 20%;
        background-color: white;
        border-radius: 15px;
        width: 60%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        padding-bottom: 2%;
}}
@media (min-width: 800px) {
    .form-wrapper-forgot-password{
        margin-top: 15%;
        background-color: white;
        border-radius: 15px;
        width: 40%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        padding-bottom: 2%;
}
}
@media (min-width: 1200px) {
    .form-wrapper-forgot-password{
        margin-top: 15%;
        background-color: white;
        border-radius: 15px;
        width: 30%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        padding-bottom: 0%;
}
}

.login-link{
    margin-top: -10px;;

}
.login-link:hover{
    text-decoration: underline;
    cursor: pointer;

}

