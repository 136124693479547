.modal-grow-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal-box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 10px #00000015;
    padding: 20px;
    max-height: 80%;
    overflow: scroll;
    margin: 30px 25px 30px 25px 
  }
  