.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.form-wrapper-reset-password{
        margin-top: 10%;
        background-color: white;
        border-radius: 15px;
        width: 30%;
        box-shadow: inset;
        -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
        padding-bottom: 0%;
}

.register-link:hover{
    text-decoration: underline;
    cursor: pointer;

}

.forgot-password{
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    margin-bottom: 0;
    align-items: center;
}
.forgot-password:hover{
    text-decoration: underline;
    cursor: pointer;

}