.custom-icon{
    height: 35px;
    width: 35px;
}
@media (min-width: 200px){
    .custom-scroll{
        width: auto;
        overflow-x: none;
    }
    .custom-container{
        width: auto
    }
}
@media (min-width: 500px){
    .custom-scroll{
        width: 700px;
        overflow-x: auto;
    }
    .custom-container{
        width: 1600px
    }
}
@media (min-width: 1000px){
    .custom-scroll{
        width: auto;
        overflow-x: none;
    }
    .custom-container{
        width: auto
    }
}

