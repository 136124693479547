/* @media (min-width: 375px) {
    .overview-table{
       max-width: 260px;
}
} */
@media (min-width: 400px) {
  .overview-table {
    max-width: 500px;
  }
}
@media (min-width: 600px) {
  .overview-table {
    max-width: 580px;
  }
}
@media (max-width: 2000px) {
  .client-group-modal {
    width: 900px;
  }
}
@media (max-width: 850px) {
  .client-group-modal {
    width: 700px;
  }
}

@media (max-width: 600px) {
  .client-group-modal {
    width: 500px;
  }
}

@media (min-width: 900px) {
  .overview-table {
    max-width: inherit;
  }
}

.table-header {
  font-weight: bold !important;
}
@media (max-width: 500px) {
  .overview-header {
    font-size: 30px;
    font-weight: bold;
    color: #094498;
  }
}
@media (min-width: 500px) {
  .overview-header {
    font-size: 48px;
    font-weight: bold;
    color: #094498;
  }
}

.menuItemTypography {
  display: flex;
  align-items: center;
  gap: 2px;
}
