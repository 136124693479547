/* width */
.bank-balance ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .bank-balance ::-webkit-scrollbar-track {
    box-shadow:  0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  .bank-balance ::-webkit-scrollbar-thumb {
    background: "transparent";
    border-radius: 10px;
  }