@media (min-width: 375px) {
    .overview-table{
       max-width: 260px;
}
}
@media (min-width: 400px) {
    .overview-table{
       max-width: 290px;
}
}
@media (min-width: 600px) {
    .overview-table{
       max-width: 580px;
}
}

@media (min-width: 900px) {
    .overview-table{
       max-width: inherit;
}
}

.table-header {
    font-weight: bold !important;
}
@media(max-width: 500px){
    .overview-header { 
        font-size: 30px;
         font-weight: bold;
          color: #094498;
         }
}
@media (min-width: 500px){
    .overview-header { 
        font-size: 48px;
         font-weight: bold;
          color: #094498;
         }
}

.nestable-drag-layer > .nestable-list {
    position: sticky !important;
  }

  .handler-pointer {
    width: 2rem;
    height: 100%;
    cursor:pointer;
  }

  .coa-container {
    border-radius: 5px;
    background-color: white;
    padding: 10px;
  }