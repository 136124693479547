.dashboard-h1 {
  font-weight: bold;
  font-size: 28px;
}

.dashboard-h2 {
  font-weight: bold;
  font-size: 18px;
}

.dashboard-h3 {
  font-weight: bold;
  font-size: 16px;
}

.dashboard-h3-normal {
  font-size: 16px;
}
@media (min-width: 300px) {
  .graph-end {
    max-width: 364px;
  }
}
@media (min-width: 700px) {
  .graph-end {
    max-width: 685px;
  }
}
@media (min-width: 1025px) {
  .graph-end {
    max-width: inherit;
  }
}
